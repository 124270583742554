import React from 'react'
import { BwTableTooltip, BwTableTooltipContent, BwTableTooltipTrigger } from './tooltip'

type HighlightTermsProps = {
  id?: string
  text: string
  terms?: Array<{ key: string; value: string }>
}

function Tooltip({ children, tooltip }) {
  return (
    <BwTableTooltip>
      <BwTableTooltipTrigger className="!relative !w-auto !inline-block [&_span]:!p-0 [&_span]:!border-dotted [&_span]:border-b-2 [&_span]:border-primaryBlue hover:cursor-help">
        {children}
      </BwTableTooltipTrigger>
      <BwTableTooltipContent>{tooltip}</BwTableTooltipContent>
    </BwTableTooltip>
  )
}

export default function HighlightTerms({ text, terms }: HighlightTermsProps) {
  if (!terms || terms.length === 0) return <>{text}</>

  const processText = (): React.ReactNode => {
    const result: React.ReactNode[] = []
    let remainingText = text

    terms.forEach(({ key, value }) => {
      const regex = new RegExp(key, 'gi')
      let match

      do {
        match = regex.exec(remainingText)
        if (match) {
          const [matchedTerm] = match
          const { index } = match

          // Add text before the match
          if (index > 0) {
            result.push(remainingText.substring(0, index))
          }
          // Add the wrapped match
          result.push(
            <Tooltip key={index} tooltip={value}>
              {matchedTerm}
            </Tooltip>
          )
          // Update remaining text
          remainingText = remainingText.substring(index + matchedTerm.length)
        }
      } while (match !== null)
      result.push(remainingText)
    })

    return <>{result}</>
  }

  return <>{processText()}</>
}
