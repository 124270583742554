import { useCallback, type ReactNode } from 'react'
import Link from '@/components/link-component'
import RichText from '@/components/ui/rich-text'
import DynamicAction, { type DynamicActionProps } from '@/components/ui/dynamic-action'
import colors from '@/constants/colors'
import { cx } from '@/utils/strings'
import type { Entry } from 'contentful'
import type { TypeComponentPricingSkeleton } from '@/types/ctf'
import { ContentfulLivePreview } from '@contentful/live-preview'
import { useLiveUpdates } from '@/utils/live-preview'
import HighlightTerms from '@/components/ui/highlight-terms'
import CtaLink from './cta-link'

type PricingCardEntry = Entry<TypeComponentPricingSkeleton, 'WITHOUT_UNRESOLVABLE_LINKS'>

export type PricingCardProps = PricingCardEntry['fields'] & {
  hasShadow?: boolean
  entryId?: string
  highlightTerms?: Array<{ id: string; key: string; value: string }>
}

export default function PricingCard({
  hasShadow = false,
  highlightTerms,
  entryId,
  ...fields
}: PricingCardProps): JSX.Element {
  const {
    fields: {
      headline,
      subHeading,
      backgroundColor = 'White',
      actionsPosition = 'Bottom',
      currency,
      pricePretext,
      price,
      period,
      periodDetail,
      subtext,
      features,
      bottomText,
      actions = [],
      columnHeading,
      setCardAsLink = false,
    },
  } = ContentfulLivePreview.liveUpdatesEnabled ? useLiveUpdates({ fields, sys: { id: entryId } }) : { fields }

  const { indigoBlue, white } = colors

  const cardWrapCss = cx(
    'mx-auto mb-4 px-8 pt-8 pb-6 max-w-2xl rounded-4xl text-center flex flex-col justify-between w-full md:px-12 md:pt-12 md:pb-8',
    backgroundColor === white && 'bg-white',
    backgroundColor === indigoBlue && 'bg-indigoBlue',
    hasShadow && 'shadow-2xl'
  )

  const cardWrapHoverStyle =
    "relative transition-transform duration-300 ease-out md:hover:scale-[1.02] md:before:content-[''] md:before:hover:opacity-30 before:rounded-4xl before:absolute before:-z-10 before:top-0 before:left-0 before:h-full before:w-full before:shadow-2xl before:shadow-black before:opacity-0 before:md:hover:opacity-30 before:transition-opacity before:duration-[250ms] before:ease-out"

  const LinkWrap = useCallback(
    ({ children, ctas }: { children: ReactNode; ctas: any }) => (
      <>
        {columnHeading && <div className="xl:hidden text-xl text-center py-2 text-white">{columnHeading}</div>}
        {setCardAsLink && ctas && ctas.length === 1 && ctas[0].fields.link ? (
          <Link
            className={cx('group', cardWrapCss, cardWrapHoverStyle)}
            to={ctas[0].fields.link.fields.url}
            aria-label={`go to ${ctas[0].fields.link.fields.url}`}
          >
            {children}
          </Link>
        ) : (
          <div className={cardWrapCss}>{children}</div>
        )}
      </>
    ),
    []
  )

  const renderActions = () => (
    <div className={`flex flex-col gap-2 ${actions.length === 1 && 'pb-9'}`}>
      {actions.map((action) => {
        if (!action) return null
        const type = action.sys.contentType.sys.id
        const fields = {
          type,
          ...action.fields,
        } as DynamicActionProps

        return setCardAsLink ? (
          <CtaLink
            style={fields.style}
            key={action.sys.id}
            align="Center"
            className=" group-hover:bg-indigoBlue group-hover:border-indigoBlue"
          >
            {fields.label}
          </CtaLink>
        ) : (
          <DynamicAction key={action.sys.id} {...fields} align="Center" backgroundColor={backgroundColor} />
        )
      })}
    </div>
  )
  return (
    <LinkWrap ctas={actions}>
      <>
        <div className={cx(backgroundColor === indigoBlue ? 'text-white' : 'text-indigoBlue')}>
          <h4 className={cx('h5', subHeading ? 'mb-2' : 'mb-12')} data-testid="pricing-headline">
            {headline}
          </h4>
          {subHeading && (
            <div className="pb-6 [p]:leading-tight">
              <RichText body={subHeading} />
            </div>
          )}
          {currency && (
            <p className={cx('mb-4', backgroundColor === white && 'text-primaryBlue')} data-testid="pricing-price">
              <span>{pricePretext}</span>
              <sup className="text-[1.5rem] md:text-[2.25rem] top-[-1.5rem]" data-testid="pricing-currency">
                {currency}
              </sup>
              <span className="text-[2.75rem] leading-10 md:text-[4rem]">{price}</span>
            </p>
          )}
          <span
            className={cx(
              backgroundColor === white && `text-gray-600`,
              backgroundColor === indigoBlue && `text-tealBlue`
            )}
          >
            {period && (
              <p className={cx('text-sm', periodDetail ? `mb-0` : `mb-6`)} data-testid="pricing-period">
                {period}
              </p>
            )}
            {periodDetail && (
              <p className="text-base mb-6" data-testid="pricing-periodDetail">
                {periodDetail}
              </p>
            )}
          </span>
          {actions && actionsPosition === 'Middle' && <div className="mb-6">{renderActions()}</div>}
          {subtext && (
            <p className="mb-6" data-testid="pricing-subtext">
              {subtext}
            </p>
          )}

          {features && (
            <div className="flex justify-center">
              <ul className="list-none p-0 lg:pl-4 mb-6 w-auto text-left" data-testid="pricing-features">
                {features.map((feature, index) => (
                  <li className="relative mb-3 pl-3" key={`feature-${index}`}>
                    <span
                      className={cx(
                        'font-bwi absolute top-0 -left-3',
                        backgroundColor === white && 'text-primaryBlue',
                        backgroundColor === indigoBlue && 'text-tealBlue'
                      )}
                    >
                      &#xe93a;
                    </span>{' '}
                    <HighlightTerms text={feature} terms={highlightTerms} />
                  </li>
                ))}
              </ul>
            </div>
          )}
          {bottomText && (
            <p className="text-sm text-gray-600 m-0" data-testid="pricing-bottom-text">
              <HighlightTerms text={bottomText} terms={highlightTerms} />
            </p>
          )}
        </div>
        {actions && (actionsPosition === 'Bottom' || actionsPosition === null) && (
          <div className="pt-6">{renderActions()}</div>
        )}
      </>
    </LinkWrap>
  )
}
